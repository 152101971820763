<template>
  <div v-if="hasResource('feedback_management')">
    <div class="feedback">
      <div class="search-form">
        <div class="dis-fix">
          <el-form :inline="true" ref="form" :model="form" :rules="rules">
            <el-form-item label="问题单号" prop="feedback_number" class="fw-500">
              <el-input
                v-model="form.feedback_number"
                placeholder="输入单号检索"
                clearable
                style="width: 152px;"
              ></el-input>
            </el-form-item>
            <el-form-item label="提交时间" class="applyDate fw-500">
              <el-date-picker
                v-model="form.created_at"
                style="width:168px"
                format="yyyy-MM-dd"
                type="daterange"
                range-separator="~"
                :maxTime="today"
                value-format="yyyy-MM-dd"
                start-placeholder="请选择"
                end-placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="问题类型" class="fw-500">
              <el-select v-model="form.type">
                <el-option
                  v-for="item in options.type"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态" class="fw-500">
              <el-select v-model="form.status">
                <el-option
                  v-for="item in options.status"
                  :key="item.name"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="search">查询</span>
            <span class="clear-button" @click="cancel">清空</span>
          </div>
        </div>
      </div>

      <el-table
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        :data="tableData"
        row-key="id"
        class="data-table"
        ref="dragTable"
        v-loading="tableLoading"
        :default-sort="{ prop: 'created_at', order: 'descending' }"
        @sort-change="sortChange"
      >
        <el-table-column
          v-for="col in tableColumns"
          :key="col.prop"
          :prop="col.prop"
          :label="col.label"
          :width="col.width"
          :align="col.align"
          :fixed="col.fixed"
          :sortable="col.prop == 'created_at' || col.prop == 'feedback_number' ? 'custom' : false"
          draggable="true"
        >
          <template slot-scope="{$index, row}">
            <div v-if="col.prop =='operation'" class="icon-btn">
              <el-button
                class="preview-color"
                v-if="hasResource('feedback_info')"
                size="mini"
                type="text"
                @click="linkDetails(row)"
              >
                <i class="iconfont">&#xe662;</i>查看
              </el-button>
              <el-button
                class="preview-color"
                v-if="row.status == 0 &&hasResource('feedback_change')"
                size="mini"
                type="text"
                @click="handledItemProblem(row)"
              >
                <i class="iconfont">&#xe65c;</i>处理
              </el-button>
            </div>
            <div v-else-if="col.prop == 'status'">
              <span>{{row[col.prop] == 1 ? '已关闭': '未关闭'}}</span>
            </div>
            <div v-else-if="col.prop == 'type'">
              <span>{{row[col.prop] == 3 ? '其它问题': row[col.prop] == 2 ? '注册问题': '充值问题'}}</span>
            </div>
            <div v-else-if="col.prop == 'created_at'">{{filterDate(row)}}</div>
            <div v-else-if="col.prop =='title'">
              <div class="title-text">{{row.title || '--'}}</div>
            </div>
            <div v-else-if="col.prop =='contact_name'">
              <div class="title-text">{{row.contact_name || '--'}}</div>
            </div>
            <div v-else>{{row[col.prop] || '--'}}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="ta-r mg-t-18"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNumber"
        :page-sizes="[10,20,50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        v-if="total!=0"
      ></el-pagination>
      <el-dialog
        top="12%"
        :visible.sync="handledProblem"
        width="458px"
        class="pop-dialog"
        :modal-append-to-body="false"
      >
        <div class="fz-18 title">您已处理了该问题？</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="confirm" class="confirm-button" :loading="btnLoading">确 认</el-button>
          <el-button @click="handledProblem = false" class="cancel-button">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import api from "../../../api/feedback";
export default {
  data() {
    var disableEndDate = (time) => {
      let _now = Date.now();
      //大于当前的禁止
      return time.getTime() > _now;
    };
    return {
      header: {
        url: "feedback",
      },
      today: new Date(),
      tableColumns: [
        {
          prop: "feedback_number",
          label: "问题单号",
          width: 180,
          align: "left",
        },
        { prop: "created_at", label: "提交时间", width: 200, align: "left" },
        { prop: "type", label: "问题类型", width: 120, align: "left" },
        { prop: "title", label: "标题", width: 200, align: "left" },
        {
          prop: "contact_name",
          label: "用户名称",
          width: 180,
          align: "left",
        },
        {
          prop: "contact_email",
          label: "用户邮箱",
          width: 220,
          align: "left",
        },
        { prop: "status", label: "状态", width: 90, align: "left" },
        {
          prop: "operation",
          label: "操作",
          align: "left",
          width: 280,
          fixed: "right",
        },
      ],
      options: {
        status: [
          { name: "全部", value: null },
          { name: "未关闭", value: 0 },
          { name: "已关闭", value: 1 },
        ],
        type: [
          { name: "全部", value: null },
          { name: "充值问题", value: 1 },
          { name: "注册问题", value: 2 },
          { name: "其他", value: 3 },
        ],
      },
      handledProblem: false,
      form: {
        status: null,
        type: null,
        feedback_number: "",
        created_at: "",
      },
      tableLoading: false,
      tableData: [],
      pageNumber: 1,
      handleObj: {},
      total: 0,
      pageSize: 10,
      order_by: "created_at",
      sort: "DESC",
      datePick: {
        disabledDate(time) {
          return disableEndDate(time);
        },
      },
      rules: {
        feedback_number: [
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
      },
      recordData: {},
      parameters: false,
      btnLoading:false
    };
  },
  computed: {
    recordQuery() {
      const params = {
        page: this.pageNumber,
        limit: this.pageSize,
        order_by: this.order_by,
        sort: this.sort,
        filter: {
          feedback_number: this.recordData.feedback_number,
          status: this.recordData.status,
          type: this.recordData.type,
          created_at: this.recordData.created_at,
        },
      };
      return params;
    },
  },
  methods: {
    async init() {
      if (this.getLocalCurrentPage("needUpdate")) {
        await this.updateFilterParams();
        await this.getFeedbackList();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getFeedbackList();
      }
    },
    getFeedbackList() {
      this.tableLoading = true;
      let data = {
        page: this.pageNumber,
        limit: this.pageSize,
        order_by: this.order_by,
        sort: this.sort,
        filter: {
          feedback_number: this.form.feedback_number,
          status: this.form.status,
          type: this.form.type,
          created_at: {
            from: this.form.created_at
              ? this.form.created_at[0] + " 00:00:00"
              : null,
            to: this.form.created_at
              ? this.form.created_at[1] + " 23:59:59"
              : null,
          },
        },
      };
      let paramsd = this.parameters ? this.recordQuery : data;
      api.feedbackList(paramsd).then((res) => {
        this.tableLoading = false;
        if (res.data.code === 10200) {
          this.tableData = res.data.data.items;
          this.total = parseInt(res.data.data.total);
        } else {
          this.$$error(res.data.message);
        }
      });
    },
    updateFilterParams() {
      this.recordData = this.getLocalCurrentPage("feedList0415")
        ? this.getLocalCurrentPage("feedList0415")
        : {};
      let params = this.recordData;
      if (params) {
        this.parameters = true;
        this.pagesize = params ? params.limit : this.pagesize;
        this.pageNumber = params ? params.page : this.pageNumber;
        this.form.feedback_number = params
          ? params.feedback_number
          : this.form.feedback_number;
        this.form.type = params ? params.type : this.form.type;
        this.form.status = params ? params.status : this.form.status;
        this.form.created_at = "";
      }
    },
    search() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.handleCurrentChange(1);
        }
      });
    },
    cancel() {
      this.form = {
        status: null,
        type: null,
        feedback_number: "",
        created_at: "",
      };
      this.recordData = {};
      this.handleCurrentChange(1);
    },
    handledItemProblem(row) {
      this.handleObj = row;
      this.handledProblem = true;
    },
    confirm() {
      this.btnLoading=true;
      let id = this.handleObj.id;
      api.closeFeedback(id).then((res) => {
        if (res.data.code === 10200) {
          this.handledProblem = false;
          this.$$success("操作成功!");
          this.init();
        } else {
          this.$$error(res.data.message);
        }
        this.btnLoading=false;
      });
    },
    linkDetails(row) {
      this.$router.push({
        path: "/feedback",
        query: {
          id: row.id,
        },
      });
      const params = {
        limit: this.pageSize,
        page: this.pageNumber,
        feedback_number: this.form.feedback_number,
        created_at: {
          from: this.form.created_at
            ? this.form.created_at[0]
              ? this.form.created_at[0] + " 00:00:00"
              : null
            : null,
          to: this.form.created_at
            ? this.form.created_at[1]
              ? this.form.created_at[1] + " 23:59:59"
              : null
            : null,
        },
        type: this.form.type,
        status: this.form.status,
      };
      this.recordCurrentPage("feedList0415", params);
    },
    filterDate(row) {
      if (row.created_at) {
        return this.$moment(row.created_at * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    },
    handleSizeChange(val) {
      this.parameters = false;
      this.pageSize = val;
      this.$set(this, "pageSize", val);
      this.init();
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.pageNumber = val;
      this.init();
    },
    sortChange(column) {
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.init();
    },
  },
  mounted() {
    this.msgBus.$on("feedList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },
  // activated() {
  //   if (this.$route.meta.keepAlive) {
  //     this.init();
  //   }
  // },
};
</script>
<style lang="less">
@import "index.less";
</style>
